import { Zoom, Fade } from "react-reveal";
import { useState } from "react";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";

const FaqItem = ({ question, answer }) => {
  const [showAnswer, setShowAnswer] = useState(false);
  const [arrowButton, setArrowButton] = useState(false);
  const handleClick = () => {
    setArrowButton(!arrowButton);
    setShowAnswer(!showAnswer);
  };

  return (
    <div className="border-b-8 border-[#000D83] py-2">
      <div className="flex justify-between items-center px-4">
        <p className="text-lg">{question.question}</p>
        <span className="text-[#000D83] p-3" onClick={handleClick}>
          <div className={`${!arrowButton && "hidden"}`}>
            <Zoom when={arrowButton}>
              <ArrowDropDownRoundedIcon fontSize="large" />
            </Zoom>
          </div>
          <div className={`${arrowButton && "hidden"}`}>
            <Zoom when={!arrowButton}>
              <ArrowRightRoundedIcon fontSize="large" />
            </Zoom>
          </div>
        </span>
      </div>
      <div
        className={`p-4 border-t-2 border-[#000D83] ${
          !arrowButton && "hidden"
        }`}
      >
        <Fade when={arrowButton}>
          <p>
            {answer}
          </p>
        </Fade>
      </div>
    </div>
  );
};

export default FaqItem;
