const testimonials = [
  {
    id: "1",
    image: "Arawole-Mercy.jpeg",
    name: "Arawole Mercy",
    course: "Web Development",
    testimony:
      "I didn't have a clue on what web development meant and was very new into technology, But all of that changed when I started learning here. The tutors, the classes, the projects are just excellent and most  importantly I love how the tutors will keep teaching you when you tell them you don't understand until you understand.",
  },
  {
    id: "2",
    image: "Victor-Obiyenwa.jpeg",
    name: "Victor Obiyenwa",
    course: "Data Science",
    testimony:
      "Cephas ICT hub has it all for me when it comes to Data Science. I enjoy learning here.",
  },
  {
    id: "3",
    image: "Oderinde-Elisha.jpg",
    name: "Oderinde Elisha",
    course: "Mobile Development",
    testimony:
      "Two years ago I knew nothing about programming, one of my biggest dream is to be a Mobile Application Developer. All thanks to Cephas Digital Technology; In Just few months as a student, I felt fulfilled. What an amazing place to learn any digital skills, I would recommend anytime and any day.",
  },
  {
    id: "4",
    image: "ajani-ben.png",
    name: "Ajani Ben",
    course: "UI/UX",
    testimony:
      "I remember how much I wanted to be a designer but I had no idea where to start. All that changed when I started learning here. It was the beginning of a dream come true.",
  },
  //   { id: "1", name: "", testimony: "" },
  //   { id: "1", name: "", testimony: "" },
  //   { id: "1", name: "", testimony: "" },
];

export default testimonials;
