import { useState } from "react";
import { createContext } from "react";

export const GlobalContext = createContext({
  userData: {},
  setUserData: () => {},
});

export const GlobalProvider = ({ children }) => {
  const [userData, setUserData] = useState({});

  const value = {
    userData,
    setUserData,
  };
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
