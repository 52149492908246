import { useNavigate, useParams } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import AppButton from "../../components/app-button/app-button.component";
import Footer from "../../components/footer/footer.component";
import CourseCard from "../../components/course-card/course-card.component";
import TestimonySlide from "../../components/testimony-slide/testimony-slide.component";
import Shake from "react-reveal/Shake";
import Swing from "react-reveal/Swing";
import Jump from "react-reveal/Jump";
import { Fade, Slide, Zoom } from "react-reveal";
import CourseTopic from "../../components/course-topic/course-topic.component";
import Standout from "../../components/standout/standout.component";
import Faq from "../../components/faq/faq.component";
import allCourses from "../../courses";
import { useEffect, useState } from "react";
import { Circles } from "react-loader-spinner";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import axios from "axios";

const CourseReview = () => {
  const navigate = useNavigate();
  const [payment, setPayment] = useState("Pay by Card");
  const [card, setCard] = useState(true);
  const [transfer, setTransfer] = useState(false);
  const [copied, setCopied] = useState(false);
  const [token, setToken] = useState("");
  const [tokenAuth, setTokenAuth] = useState(false);
  const [loading, setLoading] = useState(false)
  const goToTestimony = () => {
    navigate("/testimony");
  };
  const handleToken = (e) => {
    setToken(e.target.value);
  };
  const setPaymentType = (e) => {
    e.preventDefault();
    const val = e.target.value;
    setPayment(val);
  };
  const goToCourses = () => {
    navigate("/courses");
  };
  const baseUrl = process.env.REACT_APP_BASE_URL

  const { course } = useParams();
  const currentCourse = allCourses.filter(
    (courseItem) => courseItem.title.toLowerCase() === course.toLowerCase()
  );
  const courseView = currentCourse[0];
  // console.log(courseView);

  const goToRegister = () => {
    navigate("/register", {
      state: {...courseView, payment},
    });
  };

  const validatePayment = async (e) => {
    setTokenAuth(false);
    setLoading(true)
    e.preventDefault();
    const fData = {
      token: token,
    };

    try {
      const response = await axios.post(baseUrl + "/token", fData);
      console.log(token);
      console.log(response.data);
      if (response.data !== "Token does not exist" && token) {
        setTokenAuth(false);
        goToRegister();
        setLoading(false)
      } else {
        setLoading(false)
        setTokenAuth(true);
        return;
      }
    } catch (err) {
      console.log("my error", err);
      setLoading(false)
    }
  };

  const copyTextToClipboard = () => {
    console.log("copied");
  };

  const showCopied = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  useEffect(() => {
    switch (payment) {
      case "Pay by Card":
        setTransfer(false);
        setCard(true);
        break;
      case "Pay by Transfer":
        setTransfer(true);
        setCard(false);
        break;
      default:
        break;
    }
  }, [payment]);
  return (
    <div>
      <div className="banner container mx-auto py-16">
        <div className="grid md:grid-cols-2 gap-8">
          <div className="self-center p-4">
            <Swing>
              <p className="capitalize text-5xl md:text-4xl text-[#000D83] leading-snug">
                {courseView.title.toUpperCase()}
              </p>
              <p className="text-xl">Course Duration - {courseView.duration}</p>
              <div>
              <p className="text-xl">Price - N {courseView.price}</p>
              </div>
              <p className="mt-2 text-sm md:text-md">
                Delve into the world of creating amazing digital products and
                experiences with our hands-on training model.
              </p>
            </Swing>{" "}
            <div className="mt-4 flex">
              <select
                value={payment}
                name="payment"
                onChange={setPaymentType}
                className="h-10 rounded-md bg-indigo-200 outline-0 px-4 mr-4"
              >
                <option>Pay by Card</option>
                <option>Pay by Transfer</option>
              </select>
              <span>
                {card && (
                  <Fade>
                    <AppButton
                      children="Enroll Now"
                      buttonType="primary"
                      handleButton={goToRegister}
                    />
                  </Fade>
                )}
              </span>
            </div>
            {transfer && (
              <Fade>
                <form
                  className="flex items-top w-full mt-4"
                  onSubmit={validatePayment}
                >
                  <div className="flex flex-col">
                    <input
                      type="text"
                      placeholder="Payment code"
                      className="h-10 rounded-md bg-indigo-200 outline-0 px-4 mr-4 w-"
                      onChange={handleToken}
                    />
                    {tokenAuth && (
                      <Fade>
                        <span className="text-red-500">
                          Token does not exist
                        </span>
                      </Fade>
                    )}
                  </div>
                  <span className="relative">
                    <AppButton
                      children="Vaildate Payment"
                      buttonType="primary"
                      type="submit"
                    />
                    {loading && (
                      <Fade>
                        <div className="absolute z-10 inset-0 flex items-center justify-center">
                          <Circles
                            height="20"
                            width="20"
                            color="#EFA632"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </div>
                        <div className="absolute inset-0 bg-black opacity-50 rounded-md"></div>
                      </Fade>
                    )}
                  </span>
                </form>
              </Fade>
            )}
            {transfer && (
              <Fade>
                <div className="">
                  <div className="relative flex items-center justify-between py-1 rounded-md bg-indigo-200 outline-0 mt-4 px-4 mr-4 w-4/5 mt-2">
                    <p className="text-sm">copy account number</p>
                    <CopyToClipboard
                      text="1619358696"
                      onCopy={copyTextToClipboard}
                    >
                      <span className="p-2 cursor-pointer" onClick={showCopied}>
                        <ContentCopyRoundedIcon fontSize="small" />
                      </span>
                    </CopyToClipboard>
                    {copied && (
                      <Fade>
                        <div className="absolute -top-5 bg-green-300 p-2 rounded-md shadow-md">
                          Copied!
                        </div>
                      </Fade>
                    )}
                  </div>
                  <span className="text-sm mt-4 leading-none w-3/5">
                    Bank: Cephas ICT HUB {"(Access Bank)"} <br />
                    Send transaction receipt to this number{" "}
                    <span className="text-[#000D83] pointer-cursor">
                      07032490411
                    </span>{" "}
                    via whatsapp to receive your payment code
                  </span>
                </div>
              </Fade>
            )}
          </div>
          <div>
            <Slide right>
              <img
                src={require(`../../assets/img/courses/${courseView.image}`)}
                alt="hero"
                className="w-3/4 mx-auto md:ml-auto"
              />
            </Slide>
          </div>
        </div>
      </div>
      <section className="mb-20 py-8">
        <Shake>
          <p className="capitalize text-[#000D83] text-4xl font-medium text-center">
            become an <span className="text-[#EFA632]">industry ready</span>{" "}
            {courseView.info.title}!
          </p>
        </Shake>
        <p className="text-center mt-2 text-sm w-3/5 mx-auto">
          Our programs are designed to equip you with both soft and technical
          skills that you require to excel in your career. In{" "}
          {courseView.duration}, you will go from beginner to job ready!
        </p>
        <div className="max-w-2xl mx-auto mt-10">
          <div className="grid grid-cols-2 gap-4">
            {courseView.courseOutline.map((outline) => (
              <div className="">
                {" "}
                <CourseTopic topic={outline.module} key={outline.id} />{" "}
              </div>
            ))}
          </div>
        </div>
      </section>
      {courseView.tools.length !== 0 && (
        <section className="mb-20 py-8">
          <Shake>
            <p className="capitalize text-[#000D83] text-4xl font-medium text-center">
              learn <span className="text-[#EFA632]">important tools!</span>
            </p>
          </Shake>
          <div className="flex justify-center gap-10 mt-10 flex-wrap">
            <Zoom>
              {courseView.tools.map((tool) => (
                <img
                  src={require(`../../assets/img/UI/${tool}`)}
                  alt=""
                  className="h-12"
                  key={tool}
                />
              ))}
            </Zoom>
          </div>
        </section>
      )}
      <section className="container mx-auto mb-20 py-8">
        <Shake>
          <p className="capitalize text-[#000D83] text-4xl font-medium text-center">
            some <span className="text-[#EFA632]">related</span> courses
          </p>
        </Shake>
        <p className="text-center mt-2 text-sm w-3/5 mx-auto">
          Unleash your potential in tech with a hands-on practical curriculum
          specially designed to take you from beginner to industry ready in few
          month
        </p>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
          {allCourses
            .filter((_, idx) => idx < 3)
            .map((course) => (
              <CourseCard item={course} key={course.title} />
            ))}
        </div>
        <div className="flex justify-center mt-16">
          <Jump>
            <AppButton
              children="see more"
              buttonType="primary"
              handleButton={goToCourses}
            />
          </Jump>
        </div>
      </section>
      <section className="mb-20 py-10 relative">
        <div className="container mx-auto px-8 md:px-0">
          <Shake>
            <p className="capitalize text-[#000D83] text-4xl font-medium text-center">
              what makes us <span className="text-[#EFA632]">stand out</span>
            </p>
          </Shake>
          <Standout />
        </div>
        <div className="bg-[#3EC4FF] opacity-[.08] -z-10 absolute inset-0"></div>
      </section>
      <section className="container mx-auto px-8 md:px-0 mb-20">
        <div className="grid md:grid-cols-2 gap-8">
          <div className="self-center">
            <Zoom>
              <p className="text-[#000D83] font-bold text-4xl md:text-4xl capitalize">
                hear what our{" "}
                <span className="text-[#EFA632]">students have to say</span>
              </p>
              <p className="text-lg mt-8">
                Take a dive into the reviews of what our current students and
                alumni have to say about their experience at Cephas Digital
              </p>
            </Zoom>
            <div className="mt-8">
              <Jump>
                <AppButton
                  children="Read More"
                  buttonType="primary"
                  handleButton={goToTestimony}
                />
              </Jump>
            </div>
          </div>
          <div className="">
            <TestimonySlide />
          </div>
        </div>
      </section>
      <section className="container mx-auto my-20 md:my-40 px-8 md:px-0">
        <Faq />
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default CourseReview;
