import { Link, useNavigate } from "react-router-dom";
import AppButton from "../../components/app-button/app-button.component";
import BrandLogo from "../../components/brand-logo/brand-logo.component";

import { Zoom } from "react-reveal";
import DateRangeIcon from "@mui/icons-material/DateRange";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";

import { ReactComponent as WebIcon } from "../../assets/img/svg/web.svg";
import { ReactComponent as IotIcon } from "../../assets/img/svg/iot.svg";
import { ReactComponent as MobileIcon } from "../../assets/img/svg/mobile.svg";
import { ReactComponent as SecurityIcon } from "../../assets/img/svg/security.svg";
import { ReactComponent as DataIcon } from "../../assets/img/svg/data.svg";
import { ReactComponent as UiIcon } from "../../assets/img/svg/ux.svg";
import { ReactComponent as ProductIcon } from "../../assets/img/svg/product.svg";
import { ReactComponent as MarketingIcon } from "../../assets/img/svg/marketing.svg";
import { ReactComponent as HeroText } from "../../assets/img/svg/hero-text.svg";
import { ReactComponent as Version } from "../../assets/img/svg/version.svg";
import { ReactComponent as Mic } from "../../assets/img/svg/mic.svg";
import Footer from "../../components/footer/footer.component";
import Nav from "../../components/nav/nav.component";
import { useEffect, useState } from "react";

const bgImg = [
  "bg-1.png",
  "bg-2.png",
  "bg-3.png",
  "bg-4.png",
  "bg-5.png",
  "bg-6.png",
  "bg-7.png",
  "bg-8.png",
  "bg-9.png",
];

const courses = [
  {
    title: "IOT/Robotics/Embedded engineering",
    image: <WebIcon />,
    bg: "iot-bg.png",
  },
  {
    title: "Web Design",
    bg: "web-bg.png",
    image: <IotIcon />,
  },
  {
    title: "Mobile Application",
    image: <MobileIcon />,
  },
  {
    title: "Cyber security",
    image: <SecurityIcon />,
  },
  {
    title: "Data Science",
    image: <DataIcon />,
  },
  {
    title: "UI|UX Design",
    image: <UiIcon />,
  },
  {
    title: "Product Management",
    image: <ProductIcon />,
  },
  {
    title: "Digital Marketing",
    image: <MarketingIcon />,
  },
];

const Bootcamp = () => {
  const navigate = useNavigate();
  const goToTestimonial = () => {
    navigate("/testimony");
  };
  const goToFoundation = () => {
    navigate("/our-foundation");
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [image, setImage] = useState(bgImg[0]);

  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev === 0 ? bgImg?.length - 1 : prev - 1));
  };

  useEffect(() => {
    setImage(bgImg?.slice(currentImageIndex, currentImageIndex + 1));
  }, [bgImg, currentImageIndex]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextImage();
    }, 2000);

    return () => clearInterval(intervalId);
  }, [nextImage]);

  return (
    <div className=" bg-[#F6FAFD]">
      <Nav />
      <header>
        <div className="container mx-auto">
          <div className="grid md:grid-cols-2 gap-4 overflow-hidden">
            <div className="self-center">
              <div className="">
                <div className="w-fit md:mx-auto relative">
                  <div className="absolute inset-0 justify-center flex items-center mr-40">
                    <Version />
                  </div>
                  <HeroText />
                </div>
              </div>
              <p className="text-[#030A49] text-center sm:text-left max-w-xs ml-8 md:max-w-sm md:mx-0 mx-auto pl-4">
                Unlocking high-paying career opportunities today!
              </p>
              <div className="flex items-center max-w-xs ml-8 md:max-w-sm md:mx-0 mx-auto">
                <Mic />
                <AppButton
                  children="Starting 3rd October"
                  buttonType="primary"
                />
              </div>
            </div>
            <div className="p-4 self-center pb-10">
              <div className="relative">
                <img
                  src={require(`../../assets/img/bootcamps/hero-bg.png`)}
                  alt=""
                  className="relative -bottom-6 -left-6 max-h-[400px]"
                />
                <img
                  src={require(`../../assets/img/bootcamps/${image}`)}
                  alt=""
                  className="absolute top-0 max-h-[400px]"
                />
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="courses container mx-auto mb-20 p-8">
        <p className="font-semibold text-4xl mb-8 text-center">
          <span className="text-[#030A49]">Our</span>{" "}
          <span className="text-[#EFA632]">courses</span>
        </p>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-8">
          {courses.map((item, idx) => (
            <Zoom key={item.title}>
              <div
                className={`max-w-sm text-white ${
                  idx % 2 === 0
                    ? idx > 3
                      ? "bg-[#030A49]"
                      : "bg-[#EFA632]"
                    : idx > 3
                    ? "bg-[#EFA632]"
                    : "bg-[#030A49]"
                } rounded-xl mx-auto shadow h-[250px] min-w-[220px] w-full flex items-center justify-center relative`}
              >
                <div className="overflow-hidden inset-0 absolute">
                  {item.bg && (
                    <img
                      src={require(`../../assets/${item.bg}`)}
                      alt={item.title}
                      className="object-cover h-full opacity-40"
                    />
                  )}
                </div>
                <div className="p-4 relative z-20 flex flex-col items-center justify-center gap-4">
                  {item.image}
                  <div>
                    <p className="text-md text-center">{item.title}</p>
                  </div>
                </div>
              </div>
            </Zoom>
          ))}
        </div>
      </div>
      <div className="footer container mx-auto p-8">
        <p className="font-semibold text-4xl mb-8 text-center">
          <span className="text-[#030A49]">Our Trusted</span>{" "}
          <span className="text-[#EFA632]">Partners/Sponsors</span>
        </p>
        <div className="flex items-center justify-center py-16">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSfMtQzt5RkJmnQkXi7Hl03_cVqzGhBoMcUT0t_9eeGZ0qqBGw/viewform"
            rel="noreferrer"
            target="_blank"
            className="w-fit mx-auto"
          >
            <AppButton children="Enroll now!" buttonType="primary" />
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Bootcamp;
