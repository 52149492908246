import { Zoom } from "react-reveal";

const Standout = () => {
  return (
    <div className="grid md:grid-cols-3 gap-12 mt-8">
      <div>
        <Zoom>
          <p className="text-[#000D83] font-medium text-md">
            Accessible Learning
          </p>
          <p className="text-md">
            Regardless of your finances or location, we make design learning
            accessible to all. All you need is enthusiasm and commitment.
          </p>
        </Zoom>
      </div>
      <div>
        <Zoom>
          <p className="text-[#000D83] font-medium text-md">
            Beginner Friendly
          </p>
          <p className="text-md">
            We teach using simplified methods to achieve quick assimilation
            helping you become an amazing designer with zero prior knowledge
          </p>
        </Zoom>
      </div>
      <div>
        <Zoom>
          <p className="text-[#000D83] font-medium text-md">
            Practical Curriculum
          </p>
          <p className="text-md">
            Our curriculum ensures easy understanding of design concepts and
            propels you to becoming an intermediate designer in a short time.
          </p>
        </Zoom>
      </div>
      <div>
        <Zoom>
          <p className="text-[#000D83] font-medium text-md">
            Experienced tutors
          </p>
          <p className="text-md">
            Our tutors have a vast background in the Design field, building
            products and mentoring.
          </p>
        </Zoom>
      </div>
      <div>
        <Zoom>
          <p className="text-[#000D83] font-medium text-md">Allumni support</p>
          <p className="text-md">
            We keep a close network with all our students - current and past,
            providing support to connect them with design opportunities around
            the globe.
          </p>
        </Zoom>
      </div>
      <div>
        <Zoom>
          <p className="text-[#000D83] font-medium text-md">
            Amazing Track Record
          </p>
          <p className="text-md">
            We have taught over 300 Designers who are employed by top companies
            across the nation and internationally.
          </p>
        </Zoom>
      </div>
    </div>
  );
};

export default Standout;
