import FaqItem from "../faq-item/faq-item.component";
import Shake from "react-reveal/Shake";

const questions = [
  {
    id: 1,
    question: "What makes cephas unique?",
    answer:
      " Cephas ICT HUB is unique In the simplicity of training the student and creating good relationships within tutors and students.",
  },
  {
    id: 2,
    question: "What do i need to get started?",
    answer:
      "All you need to get started is to register your desired course and a get a good laptop, jotter and a pen.",
  },
  {
    id: 3,
    question: "Will i get a certificate?",
    answer: "Yes, you will get a certificate.",
  },
];

const Faq = () => {
  return (
    <div>
      <Shake>
        <p className="font-medium text-3xl text-[#000D83] text-center">
          Frequently asked <span className="text-[#EFA632]">questions</span>
        </p>
      </Shake>
      {questions.map((question) => (
        <FaqItem question={question} key={question.id} answer={question.answer} />
      ))}
    </div>
  );
};

export default Faq;
