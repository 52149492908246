import { useContext, useEffect } from "react";
import { useState } from "react";
import AppButton from "../../components/app-button/app-button.component";
import FormInput from "../../components/form-input/form-input.component";
import { GlobalContext } from "../../data/global-context.context";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
// import brandLogo from "../../assets/img/cephas-brand.png";

const Register = () => {
  const location = useLocation();
  console.log(location.state == null);
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state === null) {
      navigate("/courses");
      console.log("nulll");
    }
  }, [location]);
  const [formInput, setFormInput] = useState({
    fName: "",
    lName: "",
    occupation: "Student",
    level: "100 Level",
    department: "",
    email: "",
    phone: "",
    state: "",
    address: "",
    course: location.state.course,
  });

  console.log(location);

  // const inputs = [
  //   {
  //     id: 1,
  //     name: "fName",
  //     type: "text",
  //     placeholder: "First Name",
  //     errorMessage: "",
  //     label: "First Name",
  //   },
  //   {
  //     id: 2,
  //     name: "lName",
  //     type: "text",
  //     placeholder: "Last Name",
  //     errorMessage: "",
  //     label: "Last Name",
  //     pattern: "^[A-Za-z0-9]{3,16}$",
  //     required: true,
  //   },
  //   {
  //     id: 3,
  //     name: "department",
  //     type: "",
  //     placeholder: "",
  //     errorMessage: "",
  //     label: "",
  //   },
  //   {
  //     id: 4,
  //     name: "email",
  //     type: "email",
  //     placeholder: "Email",
  //     errorMessage: "",
  //     label: "Email",
  //   },
  //   {
  //     id: 5,
  //     name: "phone",
  //     type: "number",
  //     placeholder: "Phone",
  //     errorMessage: "",
  //     label: "Phone",
  //   },
  //   {
  //     id: 6,
  //     name: "state",
  //     type: "text",
  //     placeholder: "State of Origin",
  //     errorMessage: "",
  //     label: "State of Origin",
  //   },
  //   {
  //     id: 7,
  //     name: "",
  //     type: "",
  //     placeholder: "",
  //     errorMessage: "",
  //     label: "",
  //   },
  //   {
  //     id: 8,
  //     name: "",
  //     type: "",
  //     placeholder: "",
  //     errorMessage: "",
  //     label: "",
  //   },
  // ];
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const saveUserToDatabase = async () => {
    const res = await axios.post(baseUrl + "/students", formInput);
    console.log("response", res);
  };

  const savePayment = async (email, txnRef) => {
    const data = {
      email: email,
      token: txnRef,
    };

    const res = await axios.post(baseUrl + "/payments", data);
    return res.data;
  };

  const sendResponseMail = async () => {
    let fullname = formInput.lName + " " + formInput.fName;
    const res = await axios.post(baseUrl + "/send-mail", {
      title: location.title,
      image: location.image,
      name: fullname,
      email: formInput.email,
      price: location.price,
    });
    console.log(res);
  };

  const config = {
    // public_key: process.env.REACT_APP_FLW_PUBLIC_KEY,
    public_key: process.env.REACT_APP_FLW_PUBLIC_KEY_LIVE,
    tx_ref: "CP-" + Date.now(),
    amount: location.state.price,
    currency: "NGN",
    payment_options: "card,ussd",
    customer: {
      email: formInput.email,
      phonenumber: formInput.phone,
      name: formInput.lName + " " + formInput.fName,
    },
    customizations: {
      title: location.state.title.toUpperCase(),
      description: `Payment for ${location.state.title}`,
      // logo: `${brandLogo},`
    },
  };
  const handleFlutterPayment = useFlutterwave(config);

  const { setUserData } = useContext(GlobalContext);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormInput({ ...formInput, [name]: value });
  };

  const registration = async () => {
    try {
      await saveUserToDatabase();
      await sendResponseMail();
    } catch (err) {
      console.error(err);
    }
  };

  const submitForm = async (event) => {
    setUserData({ ...formInput });
    if (location.state.payment === "Pay by Transfer") {
      registration();
      navigate("/");
    } else if (location.state.payment === "Pay by Card") {
      // redirect user
      handleFlutterPayment({
        callback: async (response) => {
          console.log(response);
          if (response.status === "successful") {
            if ((await savePayment()) !== "user already exist") {
              await saveUserToDatabase();
              await sendResponseMail();
            } else {
              alert("try again");
            }
            closePaymentModal(); // this will close the modal programmatically
          }
          // redirect to homepage
        },
        onClose: () => {
          console.log("closed");
        },
      });
    }

    event.preventDefault();
  };

  return (
    <div className="container mx-auto px-8 py-12">
      <form method="POST" action="" onSubmit={submitForm} className="max-w-2xl">
        <div className="md:grid md:grid-cols-2 gap-8">
          <div className=" mt-4 md:mt-0">
            <FormInput
              label="Firstname *"
              type="text"
              name="fName"
              placeholder="First Name"
              className="h-12 p-4 rounded outline-0"
              onChange={handleChange}
              value={formInput.fName}
              required
              pattern="^[A-Za-z0-9]{3,16}$"
              errorMessage="must have maximum of 3 characters and must not have special characters"
            />
          </div>
          <div className=" mt-4 md:mt-0">
            <FormInput
              label="Lastname *"
              type="text"
              name="lName"
              placeholder="Last Name"
              onChange={handleChange}
              value={formInput.lName}
              required
              pattern="^[a-zA-Z0-9]{3,16}#$()\\-`.+,/]*$"
              errorMessage="must have maximum of 3 characters and must not have special characters"
            />
          </div>
          <div className=" mt-4 md:mt-0">
            <div className="flex flex-col gap-y-2">
              <label className="text-sm text-[#EFA632]">Occupation *</label>
              <select
                className="h-12 px-4 rounded"
                name="occupation"
                value={formInput.occupation}
                onChange={handleChange}
              >
                <option>Student</option>
                <option>Corp Member</option>
                <option>Employed</option>
                <option>Self-Employed</option>
              </select>
            </div>
          </div>
          <div className=" mt-4 md:mt-0">
            <div className="flex flex-col gap-y-2">
              <label className="text-sm text-[#EFA632]">Level</label>
              <select
                className="h-12 px-4 rounded"
                name="level"
                value={formInput.level}
                onChange={handleChange}
              >
                <option>100 Level</option>
                <option>200 Level</option>
                <option>300 Level</option>
                <option>400 Level</option>
                <option>500 Level</option>
                <option>Others</option>
              </select>
            </div>
          </div>
          <div className="col-span-2 mt-4 md:mt-0">
            <FormInput
              label="Department"
              type="text"
              name="department"
              placeholder="Department"
              onChange={handleChange}
              value={formInput.department}
            />
          </div>
          <div className="col-span-2 mt-4 md:mt-0">
            <FormInput
              label="Email *"
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              value={formInput.email}
              required
              errorMessage="must be a valid email"
            />
          </div>
          <div className=" mt-4 md:mt-0">
            <FormInput
              label="Phone number *"
              type="text"
              name="phone"
              placeholder="Phone number"
              onChange={handleChange}
              value={formInput.phone}
              required
              pattern="^[0-9]{11-15}$"
              errorMessage="must be number"
            />
          </div>
          <div className=" mt-4 md:mt-0">
            <FormInput
              label="State of Origin *"
              type="text"
              name="state"
              placeholder="State"
              onChange={handleChange}
              value={formInput.state}
              required
              pattern="^[A-Za-z]{3,16}$"
              errorMessage="This field is required"
            />
          </div>
          <div className="col-span-2 mt-4 md:mt-0">
            <div className="flex flex-col gap-y-2">
              <label>Address *</label>
              <textarea
                rows={2}
                className="rounded outline-0 p-4"
                placeholder="Address"
                name="address"
                onChange={handleChange}
                value={formInput.address}
                required
                pattern="^[A-Za-z0-9]{3,16}$"
                errorMessage="This field is required"
              />
            </div>
          </div>
        </div>
        <div className="p-4 flex items-center justify-center">
          <AppButton
            children="Submit"
            buttonType="primary"
            width="full"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default Register;
