import { Route, Routes } from "react-router-dom";
import CourseReview from "../course-review/course-review.route";
import ViewCourses from "../view-courses/view-coures.route";

const Courses = () => {
  return (
    <Routes>
      <Route index element={<ViewCourses />} />
      <Route path=":course" element={<CourseReview />} />
    </Routes>
  );
};

export default Courses;
