import { useState } from "react";
import { Zoom } from "react-reveal";

const DevCard = ({ img, title, url }) => {
  const [card, setCard] = useState(false);

  const showDevCard = () => {
    setCard(!card);
  };
  const hideDevCard = () => {
    setCard(!card);
  };

  return (
    <Zoom>
      <div
        className="max-w-sm mx-auto bg-white relative rounded-md hover:scale-105 duration-1000 ease-in-out cursor-pointer"
        onMouseEnter={showDevCard}
        onMouseLeave={hideDevCard}
      >
        {card && (
          <div className="hover:block absolute inset-0 z-50">
            <div className="absolute inset-0 bg-black opacity-50 rounded-md"></div>
            <div className="absolute inset-0 flex justify-center items-center">
              <a
                href={url}
                className="text-white opacity-100 bg-[#000D83] py-2 px-8 font-bold rounded-full"
                target="__blank"
              >
                Visit Site
              </a>
            </div>
          </div>
        )}

        <img
          src={
            img
              ? require(`../../assets/img/${img}`)
              : "https://www.shutterstock.com/image-photo/project-manager-computer-science-engineer-600nw-1798109056.jpg"
          }
          alt={title}
          className="object-cover rounded-md"
        />
        <div className="px-4 py-2">
          <p className="text-[#000D83] text-sm text-center capitalize mt-2">
            {title}
          </p>
        </div>
      </div>
    </Zoom>
  );
};

export default DevCard;
