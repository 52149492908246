import AppButton from "../../components/app-button/app-button.component";
import Footer from "../../components/footer/footer.component";
import TestimonySlide from "../../components/testimony-slide/testimony-slide.component";
import CourseCard from "../../components/course-card/course-card.component";
import "./home.styles.scss";
import Faq from "../../components/faq/faq.component";
import { Link, useNavigate } from "react-router-dom";
import Jump from "react-reveal/Jump";
import Pulse from "react-reveal/Pulse";
import Swing from "react-reveal/Swing";
import Shake from "react-reveal/Shake";
import { Zoom, Slide } from "react-reveal";
import CircleIcon from "@mui/icons-material/Circle";
import Standout from "../../components/standout/standout.component";
import allCourses from "../../courses";

const Home = () => {
  const navigate = useNavigate();
  const goToBootcamp = () => {
    navigate("/bootcamp");
  };
  const goToCourses = () => {
    navigate("/courses");
  };
  const goToTestmony = () => {
    navigate("/testimony");
  };
  return (
    <div>
    {/* <div className="relative mb-8 bg-gray-500"> */}
      <div className="absolute inset-0 -top-40">
        <img
          src={require("../../assets/background.png")}
          alt=""
          className="c object-bottom object-cover h-full w-full -md:mt40"
        />
      </div>
      <div className="container banner mx-auto px-4 py-8 md:py-16 relative z-30">
        <div className="grid md:grid-cols-2 gap-8">
          <div className="self-center p-4">
            <Swing>
              <p className="text-4xl md:text-4xl lg:text-6xl text-white leading-snug mb-8">
              Boost Your Career with Cutting-Edge IT Skills!{" "}
                <span className="text-[#EFA632] relative">
                  @ Cephas ICT HUB.
                  <img
                    src={require("../../assets/img/line.png")}
                    alt="line"
                    className="absolute inset-x-0 -bottom-3"
                  />
                </span>
              </p>
              <p className="mt-4 text-sm md:text-md text-white">
                We train people on digital skills , build dynamic software
                products for businesses and we actively volunteer our talents
                for community development.
              </p>
            </Swing>
            <div className="mt-8">
              <Jump>
             {/*   <a
                  href="https://docs.google.com/forms/d/1vG6luAPkweKXOUILkarqCrVECtj8dqqidWWgVHdqnWU/"
                  rel="noreferrer"
                  target="_blank"
                >*/}
                  <AppButton
                    children="Enroll now!"
                    buttonType="primary"
                    handleButton={goToCourses}
                  />
               {/* </a>*/}
              </Jump>
            </div>
          </div>
          {/* <div className="self-center p-4">
            <Swing>
              <p className="text-4xl md:text-4xl lg:text-5xl text-[#000D83] leading-snug lg:leading-normal mb-8">
                Kick-Start your Career in Tech at{" "}
                <span className="text-[#EFA632] relative">
                  Cephas ICT HUB.
                  <img
                    src={require("../../assets/img/line.png")}
                    alt="line"
                    className="absolute inset-x-0 -bottom-3"
                  />
                </span>
              </p>
              <p className="mt-4 text-sm md:text-md">
                Unlock your digital potential today! Experience the power of
                tech skills
                <br />
              </p>
            </Swing>
            <div className="mt-8">
              <Jump>
                <AppButton
                  techweek
                  children="Register now!"
                  buttonType="primary"
                  handleButton={goToCourses}
                />
              </Jump>
            </div>
          </div> */}
          <div>
            <Slide right>
              <img
                src={require("../../assets/img/hero.png")}
                alt="hero"
                className="w-3/4 mx-auto md:ml-auto"
              />
            </Slide>
          </div>
        </div>
      </div>
      <section className="container mx-auto mb-20 px-8 py-14 relative">
        <div className="absolute inset-0 top-120 rounded-md -z-20 overflow-hidden">
          <img
            src={require("../../assets/img/about-banner2.jpg")}
            alt="affiliate-one"
            className="h-[420px] object-cover object-center rounded-md"
          />
        </div>

        <div className="bg-gradient-to-l from-indigo-800 via-indigo-400 absolute inset-0 rounded-md -z-10"></div>
        <div className="grid md:grid-cols-2 gap-8 text-white">
          <div></div>
          <div className="relative p-4">
            <div className="absolute inset-0 bg-[#000D83] opacity-25 md:hidden -z-10"></div>
            <p className="text-4xl text-bold capitalize mb-4">
              about cephas <span className="uppercase">ict</span> training hub
            </p>
            <p className="text-sm">
              Cephas ICT HUB is the home for Data science, machine learning and
              artificial intelligence.
              <br />
              <br /> While building Management software, we prioritize the
              provision of IT solutions to all sectors. Since the establishment
              of the training institute and software development centre, more
              than 1000 students have benefitted from our top notch program and
              events…{" "}
              <Link to="/our-story">
                <span className="text-red-400 text-xl capitalize">
                  read more
                </span>
              </Link>
            </p>
          </div>
        </div>
      </section>
      <section className="mb-20 py-8">
        <Shake>
          <p className="capitalize text-[#000D83] text-4xl font-medium text-center">
            our <span className="text-[#EFA632]">affiliates</span>
          </p>
        </Shake>
        <p className="text-center mt-2 text-sm w-3/5 mx-auto">
          Unleash your creativity skill in tech with a hands-on practical
          curriculum specially designed to take you from beginner to
          intermediate in few month
        </p>
        <div className="container mx-auto mt-8 px-8">
          <div className="grid md:grid-cols-3 gap-4 md:gap-8">
            <Zoom>
              <Link to="/courses">
                <div className="max-w-sm mx-auto rounded-md hover:scale-105 duration-1000 ease-in-out cursor-pointer bg-white shadow-md">
                  <div className="relative">
                    <div class="bg-gradient-to-b from-indigo-500 absolute inset-0 rounded-md"></div>
                    <img
                      src={require("../../assets/img/cephas-training.jpg")}
                      alt="affiliate-one"
                      className="object-cover rounded-t-md h-48 w-full"
                    />
                  </div>
                  <p className="text-[#000D83] text-xl text-center capitalize mt-2 p-4">
                    Cephas Training institute
                  </p>
                </div>
              </Link>
            </Zoom>
            <Zoom>
              <Link to="/developers">
                <div className="max-w-sm bg-white shadow-md mx-auto rounded-md hover:scale-105 duration-1000 ease-in-out cursor-pointer">
                  <img
                    src={require("../../assets/img/cedo-guys.jpg")}
                    alt="affiliate-two"
                    className="object-cover rounded-t-md h-48 w-full"
                  />
                  <p className="text-[#000D83] text-xl text-center capitalize mt-2 p-4">
                    Cephas Developers' Ocean (CEDO)
                  </p>
                </div>
              </Link>
            </Zoom>
            <Zoom>
              <Link to="/our-foundation">
                <div className="max-w-sm bg-white shadow-md mx-auto rounded-md hover:scale-105 duration-1000 ease-in-out cursor-pointer">
                  <img
                    src={require("../../assets/img/bootcamp.jpg")}
                    alt="affiliate-two"
                    className="object-cover rounded-t-md h-48 w-full"
                  />
                  <p className="text-[#000D83] text-xl text-center capitalize mt-2 p-4">
                    Cephas digital foundation
                  </p>
                </div>
              </Link>
            </Zoom>
          </div>
        </div>
      </section>
      <section className="container mx-auto mb-20 py-8 px-8 md:px-0">
        <Shake>
          <p className="capitalize text-[#000D83] text-4xl font-medium text-center">
            kick-start your{" "}
            <span className="text-[#EFA632]">carrer in tech</span>
          </p>
        </Shake>
        <p className="text-center mt-2 text-sm w-4/5 md:w-3/5 mx-auto">
          Unleash your creativity skill in tech with a hands-on practical
          curriculum specially designed to take you from beginner to
          intermediate in few month
        </p>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-12 mt-8">
          {allCourses
            .filter((_, idx) => idx < 8)
            .map((course) => (
              <CourseCard item={course} key={course.title} />
            ))}
          {/* {courses.map((course) => (
            <CourseCard item={course} key={course.title} />
          ))} */}
          <Pulse>
            <div
              className="flex justify-center flex-col gap-4 items-center bg-[#000D83] text-white rounded-md max-w-xs mx-auto p-10 md:p-20 cursor-pointer hover:scale-105 duration-1000 ease-in-out"
              onClick={goToCourses}
            >
              <div className="max-w-sm "></div>
              <p className="font-medium text-8xl">+7</p>
              <p className="text-md font-light">more available courses</p>
            </div>
          </Pulse>
        </div>
      </section>
      <section className="mb-20 py-10 relative">
        <div className="container mx-auto px-8 md:px-0">
          <Shake>
            <p className="capitalize text-[#000D83] text-4xl font-medium text-center">
              what makes us <span className="text-[#EFA632]">stand out</span>
            </p>
          </Shake>
          <Standout />
        </div>
        <div className="bg-[#3EC4FF] opacity-[.08] -z-10 absolute inset-0"></div>
      </section>
      <section className="container mx-auto mb-20 px-8 md:px-0">
        <div className="grid md:grid-cols-2 gap-8">
          <div className="self-center">
            <Zoom>
              <p className="text-[#000D83] font-bold text-4xl capitalize">
                hear what our{" "}
                <span className="text-[#EFA632]">students have to say</span>
              </p>
              <p className="text-lg mt-8">
                Take a dive into the reviews of what our current students and
                alumni have to say about their experience at Cephas Digital
              </p>
            </Zoom>
            <div className="mt-8">
              <Jump>
                <AppButton
                  children="Read More"
                  buttonType="primary"
                  handleButton={goToTestmony}
                />
              </Jump>
            </div>
          </div>
          <div className="">
            <TestimonySlide />
          </div>
        </div>
      </section>
      <section className="mb-20 p-8">
        <Zoom>
          <div className="m-8 max-w-6xl p-10 text-left bg-gradient-to-l from-[#030A49] via-indigo-700 to-indigo-900 rounded-lg mx-auto text-white flex gap-x-10 items-center">
            <div>
              <p className="text-white capitalize font-bold text-3xl">
                Become our student
              </p>
              <p className="my-4 text-md text-gray-300">
                you can become a{" "}
                <span className="text-[#EFA632]">Tech Savvy,</span> navigate
                through the job market and advance in your career within the
                next few months
              </p>
              <AppButton
                children="Enroll Now"
                buttonType="white"
                handleButton={goToCourses}
              />
            </div>
            <div className="hidden md:inline-block">
              <img
                src={require("../../assets/img/web-developer.png")}
                alt="enroll"
                className="w-38"
              />
            </div>
          </div>
        </Zoom>
      </section>
      <section className="container mx-auto mb-20 px-8 md:px-0">
        <Faq />
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default Home;
