import TestimonyCard from "../testimony-card/testimony-card.component";

import testimonials from "../../testmonial";

import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

const TestimonySlide = () => {
  const slideLeft = () => {
    console.log("working");
    let slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 200;
  };

  const slideRight = () => {
    let slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 200;
  };
  return (
    <div>
      <div
        id="slider"
        className="overflow-x-scroll w-[320px] sm:w-full scroll whitespace-nowrap scroll scroll-smooth scrollbar-hide mb-12"
      >
        {testimonials.map((testimonial) => (
          <div className="w-[250px] cursor-pointer shadow-xl bg-white whitespace-normal rounded-md inline-block p-4 ml-4 hover:scale-105 hover:shadow-md ease-in-out duration-1000">
            <TestimonyCard
              key={testimonial.id}
              name={testimonial.name}
              image={testimonial.image}
              testimony={testimonial.testimony}
            />
          </div>
        ))}
      </div>
      <div className="flex justify-end">
        <span
          className="py-4 px-2 bg-white rounded-md shadow-lg mr-2 cursor-pointer "
          onClick={slideLeft}
        >
          <ArrowBackIosNewRoundedIcon />
        </span>
        <span
          className="py-4 px-2 bg-white rounded-md shadow-lg mr-2 cursor-pointer"
          onClick={slideRight}
        >
          <ArrowForwardIosRoundedIcon />
        </span>
      </div>
    </div>
  );
};

export default TestimonySlide;
