import AppButton from "../../components/app-button/app-button.component";
import TestimonyCard from "../../components/testimony-card/testimony-card.component";
import Footer from "../../components/footer/footer.component";
import CourseCard from "../../components/course-card/course-card.component";
import Faq from "../../components/faq/faq.component";
import Standout from "../../components/standout/standout.component";
import Shake from "react-reveal/Shake";
import Jump from "react-reveal/Jump";
import Swing from "react-reveal/Swing";
import { Zoom } from "react-reveal";

import testimonials from "../../testmonial";
import { useNavigate } from "react-router-dom";

const courses = [
  {
    title: "UI|UX (Product) design",
    duration: "3 Months",
    image: "UI-UX.jpeg",
  },
  {
    title: "Software Engineering",
    duration: "12 Months",
    image: "software-Engineering.jpeg",
  },
  {
    title: "Data Science & Machine Learning",
    duration: "12 Weeks",
    image: "dat- science.jpeg",
  },
];

const Testimony = () => {
  const navigate = useNavigate();
  const goToCourses = () => {
    navigate("/courses");
  };
  return (
    <div>
      <section className="banner container mx-auto py-16">
        <div className="grid md:grid-cols-2 gap-10">
          <div className="self-center p-4">
            <Swing>
              <p className="capitalize text-5xl md:text-4xl lg:text-6xl text-[#000D83] leading-snug">
                Here are a few{" "}
                <span className="text-[#EFA632]">testimonials</span> to make
                your day!
              </p>
            </Swing>
          </div>
          <div>
            <Zoom>
              <img
                src={require("../../assets/img/testimony-hero.png")}
                alt="testimonial hero"
                className="w-3/4 mx-auto md:ml-auto"
              />
            </Zoom>
          </div>
        </div>
        <div className="flex justify-center mt-10">
          <Jump>
            <AppButton children="Read success stories" buttonType="primary" />
          </Jump>
        </div>
      </section>
      <section className="container mx-auto mt-12 md:mt-20 py-8">
        <div className="flex flex-wrap gap-16">
          {testimonials.map((testimonial) => (
            <div className="w-56 mx-auto">
              <TestimonyCard
                key={testimonial.id}
                name={testimonial.name}
                image={testimonial.image}
                testimony={testimonial.testimony}
              />
            </div>
          ))}
        </div>
      </section>
      <section className="container mx-auto mt-20 md:mt-20 py-8 px-8 md:px-0">
        <Shake>
          <p className="capitalize text-[#000D83] text-4xl font-medium text-center">
            kick-start your{" "}
            <span className="text-[#EFA632]">carrer in tech</span>
          </p>
        </Shake>
        <p className="text-center mt-2 text-sm w-4/5 md:w-3/5 mx-auto">
          Unleash your creativity skill in tech with a hands-on practical
          curriculum specially designed to take you from beginner to
          intermediate in few month
        </p>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
          {courses.map((course) => (
            <CourseCard item={course} key={course.title} />
          ))}
        </div>
        <div className="flex justify-center mt-16">
          <Jump>
            <AppButton
              children="see more"
              buttonType="secondary"
              handleButton={goToCourses}
            />
          </Jump>
        </div>
      </section>
      <section className="mt-20 md:mt-40 py-10 relative">
        <div className="container mx-auto px-8 md:px-0">
          <Shake>
            <p className="capitalize text-[#000D83] text-4xl font-medium text-center">
              what makes us <span className="text-[#EFA632]">stand out</span>
            </p>
          </Shake>
          <Standout />
        </div>
        <div className="bg-[#3EC4FF] opacity-[.08] -z-10 absolute inset-0"></div>
      </section>
      <section className="container mx-auto my-20 md:my-40 px-8 md:px-0">
        <Faq />
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default Testimony;
