import { useState } from "react";
import './form-input.css'

const FormInput = ({ label, errorMessage, pattern, ...restProps }) => {
  const [focused, setFocused] = useState(false);
  const handleFocus = () => {
    setFocused(true)
  };

  return (
    <div className="flex flex-col gap-y-2 format">
      <label className="text-sm text-[#EFA632]">{label}</label>
      <input
        {...restProps}
        className="h-12 p-4 rounded outline-0"
        onBlur={handleFocus}
        focused={focused.toString()}
        pattern={pattern}
      />
      <span className="text-xs text-red-400 p-1">{errorMessage}</span>
    </div>
  );
};

export default FormInput;
