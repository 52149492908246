import { Zoom } from "react-reveal";
import Shake from "react-reveal/Shake";
import Footer from "../../components/footer/footer.component";

const images = [
  "1.jpg",
  "2.jpg",
  "3.jpg",
  "4.jpg",
  "5.jpg",
  "6.jpg",
  "7.jpg",
  "8.jpg",
  "9.jpg",
  "10.jpg",
  "11.jpg",
  "12.jpg",
  "13.jpg",
  "14.jpg",
  "15.jpg",
  "16.jpg",
  "17.jpg",
  "18.jpg",
];

const Foundation = () => {
  return (
    <div>
      <section className="banner container mx-auto py-16 px-8 md:px-0">
        <div className="md:flex gap-12">
          {/* <Flip> */}
          <div className="flex justify-center items-center bg-[#000D83] rounded-2xl w-full md:w-2/5 mb-10 md:mb-0 p-8">
            <img
              src={require("../../assets/img/cephas-brand-white.png")}
              alt="brang logo"
              className="h-[100px]"
            />
          </div>
          {/* </Flip> */}
          <div className="md:w-3/5">
            <Shake>
              <p className="capitalize text-[#000D83] text-4xl font-medium text-center mb-10">
                About <span className="text-[#EFA632]">our foundation</span>
              </p>
            </Shake>
            <Zoom>
              <p>
                We Actively engage our talents for community development by
                organising free bootcamp training annually and Tech
                week(Quarterly) to raise proficient and competent Technologist
                in Africa.
                <br />
                <br /> The Journey began in November 2020 when we organised free
                bootcamp training for 500 students who benefited tremendously
                from the training. We are committed to community building
                through digital technologies and our goal is to raise competent
                youths for national and global transformation via digital technology.
              </p>
            </Zoom>
          </div>
        </div>
      </section>
      <section className="my-8">
        <Shake>
          <p className="capitalize text-[#000D83] text-2xl font-medium text-center">
            our journey <span className="text-[#EFA632]">so far</span>
          </p>
        </Shake>
        <p className="text-center mt-2 text-sm w-4/5 md:w-3/5 mx-auto mb-12">
          Unleash your creativity skill in tech with a hands-on practical
          curriculum specially designed to take you from beginner to
          intermediate in few month
        </p>
        <div className="container mx-auto p-4 md:p-0 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mb-20">
          {images.map((image) => (
            <div className="">
              <img
                src={require(`../../assets/img/bootcamps/${image}`)}
                alt="affiliate-two"
                className="object-cover object-top rounded-t-md h-52 w-full"
              />
            </div>
          ))}
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default Foundation;
