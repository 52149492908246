const team = [
  {
    id: 1,
    name: "Adediran Oluwakayode",
    job: "Frontend Developer",
    image: "kayode.jpg",
  },
  {
    id: 2,
    name: "Olawale Micheal",
    job: "Backend Developer",
    image: "michael.jpeg",
  },
  { id: 3, name: "Ajani Ben", job: "UI/UX Designer", image: "ben.png" },
  { id: 4, name: "Odewunmi Esther", job: "Secretary", image: "esther.jpeg" },
  {
    id: 5,
    name: "Adaralegbe Ifeoluwa",
    job: "Fullstack Developer",
    image: "ifeoluwa.jpeg",
  },
  { id: 6, name: "Oluwole Odunayo", job: "Marketer", image: "Odunayo.png" },
  {
    id: 7,
    name: "Oderinde Elisha",
    job: "Mobile Developer",
    image: "elisha.jpg",
  },
  {
    id: 7,
    name: "Ayoola Deborah",
    job: "UI/UX Designer",
    image: "ayoola.jpeg",
  },
];

export default team;
