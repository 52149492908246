import Footer from "../../components/footer/footer.component";
import Standout from "../../components/standout/standout.component";
import TeamCard from "../../components/team-card/team-card.component";
import Shake from "react-reveal/Shake";
import { Zoom, Flip } from "react-reveal";
import team from "../../team";

const OurStory = () => {
  return (
    <div>
      <section className="banner container mx-auto py-16 px-8 md:px-0">
        <div className="md:flex gap-12">
          <Flip>
            <div className="flex justify-center items-center bg-[#000D83] rounded-2xl w-full md:w-2/5 mb-10 md:mb-0 p-8">
              <img
                src={require("../../assets/img/cephas-brand-white.png")}
                alt="brang logo"
                className="h-[100px]"
              />
            </div>
          </Flip>
          <div className="md:w-3/5">
            <Shake>
              <p className="capitalize text-[#000D83] text-4xl font-medium text-center mb-10">
                About <span className="text-[#EFA632]">Us</span>
              </p>
            </Shake>
            <Zoom>
              <p>
                Cephas ICT HUB is the home for Data science, machine learning
                and artificial intelligence. While building Management software,
                we prioritise the provision of IT solutions to all sectors.
                Since the establishment of the training institute and software
                development centre, more than 1000 students have benefitted from
                our top notch program and events.
                <br /> We have trained more than 1000 students on several
                digital skills
                <br /> The IT firm was founded in February 2019, and till date
                we have built apps and software for many businesses.
                <br /> Due to our passion to impact our community, we also
                organise an annual free Bootcamp training for students. In
                September 2020, over 500people registered for STEM Bootcamp
                training and we recorded over 300 consistent students within the
                four (4) weeks of a free training.
                <br />
                We Build Web and Mobile Apps for Businesses with a high level of
                proficiency in different industries like Fintech, ecommerce,
                Education, Business Management, Health, Blockchain.
                <br />
                We also Build intelligent models to enhance the performance of
                our software through ML/AI Technologies.
                <br />
              </p>
            </Zoom>
          </div>
        </div>
      </section>
      <section className="mt-20 md:mt-40 py-10 relative">
        <div className="container mx-auto px-8 md:px-0">
          <Shake>
            <p className="capitalize text-[#000D83] text-4xl font-medium text-center">
              what makes us <span className="text-[#EFA632]">stand out</span>
            </p>
          </Shake>
          <Standout />
        </div>
        <div className="bg-[#3EC4FF] opacity-[.08] -z-10 absolute inset-0"></div>
      </section>
      <section className="mt-20 md:mt-40 py-10 relative mb-28">
        <div className="container mx-auto px-8 md:px-0">
          <Shake>
            <p className="capitalize text-[#000D83] text-4xl font-medium text-center">
              our <span className="text-[#EFA632]">team</span>
            </p>
          </Shake>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
            {team.map((member) => (
              <TeamCard
                photo={member.image}
                name={member.name}
                job={member.job}
                key={member.id}
              />
            ))}
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default OurStory;
