import { Zoom } from "react-reveal";

const CourseTopic = ({topic}) => {
  return (
    <Zoom>
      <div className="flex items-center justify-left gap-4">
        <div className="h-2 w-2 rounded-full bg-black"></div>
        <p className="m-0">{topic}</p>
      </div>
    </Zoom>
  );
};

export default CourseTopic;
