import AppButton from "../../components/app-button/app-button.component";
import Footer from "../../components/footer/footer.component";
import CourseCard from "../../components/course-card/course-card.component";
import TestimonySlide from "../../components/testimony-slide/testimony-slide.component";
import Standout from "../../components/standout/standout.component";
import Shake from "react-reveal/Shake";
import Jump from "react-reveal/Jump";
import { Zoom } from "react-reveal";
import Faq from "../../components/faq/faq.component";
import allCourses from "../../courses";

const ViewCourses = () => {
  return (
    <div>
      <section className="banner container mx-auto py-16 px-8">
        <Shake>
          <p className="capitalize text-[#000D83] text-4xl font-medium text-center">
            kick-start your{" "}
            <span className="text-[#EFA632]">carrer in tech</span>
          </p>
        </Shake>
        <p className="text-center mt-2 text-sm w-4/5 md:w-3/4 mx-auto">
          Unleash your potential in tech with a hands-on practical
          curriculum specially designed to take you from beginner to
          intermediate in few month
        </p>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
          {allCourses.map((course) => (
            <CourseCard item={course} key={course.title} />
          ))}
        </div>
      </section>
      <section className="mt-20 md:mt-40 py-10 relative">
        <div className="container mx-auto px-8 md:px-0">
          <Shake>
            <p className="capitalize text-[#000D83] text-4xl font-medium text-center">
              what makes us <span className="text-[#EFA632]">stand out</span>
            </p>
          </Shake>
          <Standout />
        </div>
        <div className="bg-[#3EC4FF] opacity-[.08] -z-10 absolute inset-0"></div>
      </section>
      <section className="container mx-auto mt-20 md:mt-40 mb-10 px-8 md:px-0">
        <div className="grid md:grid-cols-2 gap-8">
          <div className="self-center">
            <Zoom>
              <p className="text-[#000D83] font-bold text-4xl md:text-4xl capitalize">
                hear what our{" "}
                <span className="text-[#EFA632]">students have to say</span>
              </p>
              <p className="text-lg mt-8">
                Take a dive into the reviews of what our current students and
                alumni have to say about their experience at Cephas Digital
              </p>
            </Zoom>
            <div className="mt-8">
              <Jump>
                <AppButton children="Read More" buttonType="primary" />
              </Jump>
            </div>
          </div>
          <div className="">
            <TestimonySlide />
          </div>
        </div>
      </section>
      <section className="container mx-auto my-20 md:my-40 px-8 md:px-0">
        <Faq />
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default ViewCourses;
