import TeamCard from "../../components/team-card/team-card.component";
import Shake from "react-reveal/Shake";
import Footer from "../../components/footer/footer.component";
import DevCard from "../../components/dev-card/dev-card.component";
import team from "../../team";
import AppButton from "../../components/app-button/app-button.component";

const portfolio = [
  {
    id: "1",
    name: "Lagos State Volunteer Corps:  project for Lagos state.",
    link: "http://lsvc.ng",
    image: "lsvc.png",
  },
  {
    id: "2",
    name: "Quotes connect procurement web app",
    link: "https://www.quotesconnect.com",
    image: "quotes-connect.png",
  },
  {
    id: "4",
    name: "Lagos state performance tracker project.",
    link: "https://pmr-v2.vercel.app",
    image: "tracker.png",
  },
  {
    id: "5",
    name: "Grant application portal for Lagos state.",
    link: "https://grants.lsvc.ng/login",
    image: "sdg-grant.png",
  },
  {
    id: "6",
    name: "School management system for a college in Ibadan.",
    link: "http://portal.guarverd.sch.ng/public",
    image: "guarverd.png",
  },
  {
    id: "7",
    name: "Sreenclass App for e-learning.",
    link: "https://play.google.com/store/apps/details?id=com.screenclass&hl=en&gl=US",
    image: "screenclass.png",
  },
  {
    id: "8",
    name: "Restaurant and delivery system for a client in UK",
    link: "https://tastyhasty.co.uk/",
    image: "tastyhasty.png",
  },
  {
    id: "9",
    name: "Honor World mobile app",
    link: "https://play.google.com/store/apps/details?id=com.ng.honourworld&hl=en&gl=US",
    image: "honor-world.png",
  },
  {
    id: "10",
    name: "Traffic Age Project for Lagos Ride",
    link: "https://traffic-age.vercel.app/",
    image: "traffic-age.png",
  },
  {
    id: "11",
    name: "GIS Application for Bill and Melinda Gates Foundation",
    link: "https://www.geo-rolodex.com/",
    image: "rolodex.png",
  },
  {
    id: "12",
    name: "ICS academy",
    link: "https://icsacademy-student.vercel.app/",
    image: "ics-academy.png",
  },
  {
    id: "13",
    name: "LMS platform for BCT Limited",
    link: "https://www.mywebschools.com",
    image: "bct-lms.png",
  },
  {
    id: "14",
    name: "Blog for Lagosride/ Lagride || Operating in Lagos",
    link: "https://lagosride.com",
    image: "lagride-blog.png",
  },
  {
    id: "15",
    name: "Lagos state performance tracker project",
    link: "https://pmr-v2.vercel.app",
    image: "pmr.png",
  },
  // {
  //   id: "16",
  //   name: "Restaurant and delivery system for a client in UK",
  //   link: "https://tastyhasty.co.uk ",
  //   image: "",
  // },
  {
    id: "17",
    name: "AI Model: Cancer Disease Detection for Ajayi Crowther University Student, Oyo Town.",
    link: "",
    image: "",
  },
  // {
  //   id: "18",
  //   name: "Sreenclass App for e-learning",
  //   link: "https://play.google.com/store/apps/details?id=com.screenclass&hl=en&gl=US",
  //   image: "",
  // },
  {
    id: "19",
    name: "Fruit Disease Detection for Food Science and Engineering Student, LAUTECH",
    link: "",
    image: "",
  },
  {
    id: "20",
    name: "Quiz application for EDTECH",
    link: "https://get-2-know.com/",
    image: "edtech.png",
  },
  {
    id: "21",
    name: "Ecommerce App Ileoja",
    link: "https://play.google.com/store/apps/details?id=com.ileoja.marketplace&hl=en&gl=US",
    image: "",
  },
  {
    id: "22",
    name: "Logistic Web APP",
    link: "http://www.viscio.com.ng/",
    image: "visco.png",
  },
  {
    id: "23",
    name: "Breast Cancer Disease Detection with AI/Machine Learning",
    link: "https://github.com/Lovej4972/BreastCancerDetection",
    image: "",
  },
];

const Developers = () => {
  return (
    <div>
      <section className="container mx-auto my-20 px-8 py-14 relative">
        <div className="absolute inset-0 top-120 rounded-md -z-20 overflow-hidden">
          <img
            src={require("../../assets/img/about-banner.jpg")}
            alt="affiliate-one"
            className="object-cover object-center rounded-md"
          />
        </div>

        <div className="bg-indigo-800 opacity-75 absolute inset-0 rounded-md -z-10"></div>
        <div className="">
          <div>
            <Shake>
              <p className="capitalize text-white text-4xl font-bold text-center mb-4 max-w-2xl mx-auto">
                Projects handled by{" "}
                <span className="text-[#EFA632]">our competent developers</span>
              </p>
            </Shake>
            <p className="text-center text-white mt-2 text-sm w-4/5 md:w-3/4 mx-auto">
              Do you have an idea you want to turn to a Software product or
              implement through digital technology. You can get your job done
              easily without stress through our competent developers
            </p>
            <div className="flex justify-center pt-8">
              <a href="https://wa.link/6wj4r8">
                <AppButton buttonType={"secondary"} children={" Hire us now"} />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="banner container mx-auto mb-20 px-8">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mt-20">
          {portfolio.map((project) => (
            <DevCard
              id={project.id}
              img={project?.image}
              url={project?.link}
              title={project?.name}
            />
          ))}
        </div>
      </section>
      <section className="mb-20 py-10 relative">
        <div className="container mx-auto px-8 md:px-0">
          <Shake>
            <p className="capitalize text-[#000D83] text-4xl font-medium text-center">
              our <span className="text-[#EFA632]">team</span>
            </p>
          </Shake>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
            {team.map((member) => (
              <TeamCard
                photo={member.image}
                name={member.name}
                job={member.job}
                key={member.id}
              />
            ))}
          </div>
        </div>
      </section>
      <div className="flex justify-center py-8">
        <a href="https://wa.link/6wj4r8">
          <AppButton buttonType={"primary"} children={" Hire us now"} />
        </a>
      </div>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default Developers;
