import Zoom from "react-reveal/Zoom";

const TestimonyCard = ({ image, testimony, name, course }) => {
  return (
    <div>
      <Zoom>
        <div className="relative w-14 h-14 rounded-full mx-auto">
          <img
            src={require(`../../assets/img/${image}`)}
            alt="sss"
            className="w-full h-full rounded-full object-cover"
          />
          <img
            src={require(`../../assets/img/${image}`)}
            alt="zzz"
            className="absolute -bottom-1 -right-1 w-8 h-8 rounded-full object-cover"
          />
        </div>
        <p className="text-center text-md italic mt-4 h-40 overflow-y-auto scroll scroll-smooth scrollbar-hide">
          {testimony}
        </p>
        <div className="w-10 h-[2px] mx-auto bg-black mt-4"></div>
        <p className="text-center mt-4 text-lg">{name}</p>
        <p className="text-center mt-2 text-[#000D83] font-medium text-lg">
          {course}
        </p>
      </Zoom>
    </div>
  );
};

export default TestimonyCard;
