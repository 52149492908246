import { Zoom } from "react-reveal";

const TeamCard = ({ photo, name, job }) => {
  return (
    <Zoom>
      <div className="max-w-sm h-38 rounded-md relative mb-10 mx-auto">
        <img
          src={require(`../../assets/img/team/${photo}`)}
          alt="team member"
          className="object-cover object-top w-full h-full rounded-md"
        />
        <div className="p-4 rounded-md absolute left-0 -bottom-10 bg-white w-3/4 shadow-xl">
          <p className="font-medium text-xl text-[#211F54] capitalize">
            {name}
          </p>
          <p className="text-sm text-[#031A6E] uppercase">{job}</p>
        </div>
      </div>
    </Zoom>
  );
};

export default TeamCard;
