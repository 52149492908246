const BUTTON_TYPE_CLASSES = {
  primary: "bg-[#000D83] text-white",
  secondary: "bg-[#EFA632] text-white",
  white: "bg-white text-[#000D83]",
};

const BUTTON_WIDTH = {
  full: "w-full",
};

const AppButton = ({
  buttonType,
  children,
  width,
  handleButton,
  techweek,
  ...otherProps
}) => {
  return (
    <div>
{
  techweek?
  <a
  {...otherProps}
  className={`px-4 py-2 rounded-md font-light whitespace-nowrap ${BUTTON_TYPE_CLASSES[buttonType]} ${BUTTON_WIDTH[width]}`}
  // onClick={handleButton}
  href="https://docs.google.com/forms/d/e/1FAIpQLSe88_g8g9w-zah-fJ-aQbpn4i8CiYWiG7LPUiSMsJZVEqLzZQ/viewform"
>
  {children}
</a>:
    <button
    {...otherProps}
    className={`px-4 py-2 rounded-md font-light whitespace-nowrap ${BUTTON_TYPE_CLASSES[buttonType]} ${BUTTON_WIDTH[width]}`}
    onClick={handleButton}
  >
    {children}
  </button>
}

    </div>

  );
};

export default AppButton;
