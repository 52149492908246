import BrandLogo from "../brand-logo/brand-logo.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer bg-[#030A49] py-8 text-white">
      <div className="container mx-auto px-8 md:px-0">
        <div className="flex gap-8 justify-between flex-wrap align-top">
          <div className="">
            <BrandLogo logoType="white" height="h-16" />
            <p className="text-xs md:max-w-xs mt-4 text-gray-400">
              Cephas Digital, Adjacent Seventh day Adventist Student Fellowship,
              Under G., Ogbomoso, Nigeria.
            </p>
          </div>
          <div>
            <p className="mb-4 font-medium text-xl">Explore</p>
            <ul className="text-xs text-gray-400">
              <li className="mt-2">
                <Link to="/">Home</Link>
              </li>
              <li className="mt-2">
                <Link to="/our-story">Our Story</Link>
              </li>
              <li className="mt-2">
                <Link to="/testimony">Testimonial</Link>
              </li>
              <li className="mt-2">
                <Link to="/courses">Our Courses</Link>
              </li>
              <li className="mt-2">
                <Link to="/">FAQs</Link>
              </li>
            </ul>
          </div>
          <div>
            <p className="mb-4 font-medium text-xl">Our courses</p>
            <ul className="text-xs text-gray-400">
              <li className="mt-2">
                <Link to="/courses/UI|UX (Product) design">UI|UX Design</Link>
              </li>
              <li className="mt-2">
                <Link to="/courses/Software Development">
                  Software Development
                </Link>
              </li>
              <li className="mt-2">
                <Link to="/courses/Graphics Design">Graphics Design</Link>
              </li>
              <li className="mt-2">
                <Link to="/courses/Digital Marketing">Digital Marketing</Link>
              </li>
              <li className="mt-2">
                <Link to="/courses">See More</Link>
              </li>
            </ul>
          </div>
          <div>
            <p className="mb-4 font-medium text-xl">Contact Us</p>
            <ul className="text-xs text-gray-400">
              <li className="mt-2 flex items-center gap-x-2">
                <LocalPhoneRoundedIcon />
                <div>
                  <p>+2348063260342</p>
                  <p>+2347060586105</p>
                </div>
              </li>
              <li className="mt-2 flex items-center gap-x-2">
                <MailOutlineRoundedIcon />
                <p>cephasict@gmail.com</p>
              </li>
            </ul>
          </div>
          <div>
            <p className="text-xs text-gray-400 mb-2">connect with us</p>
            <div className="flex gap-4">
              <a href="https://web.facebook.com/cephasict" target="_blank">
                <FontAwesomeIcon icon="fa-brands fa-facebook" />
              </a>
              <a href="https://twitter.com/Cephasict" target="_blank">
                <FontAwesomeIcon icon="fa-brands fa-twitter" />
              </a>
              <a href="/" target="_blank">
                <FontAwesomeIcon icon="fa-brands fa-whatsapp" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
