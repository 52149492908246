import { Routes, Route } from "react-router-dom";
import Nav from "./components/nav/nav.component";
import Home from "./routes/home/home.route";
import OurStory from "./routes/our-story/our-story.route";
import Testimony from "./routes/testimony/testimony.route";
import Courses from "./routes/courses/courses.route";

import "./App.css";
import Register from "./routes/register/register.route";
import Developers from "./routes/developers/developers.route";
import Foundation from "./routes/foundations/foundations.route";
import Bootcamp from "./routes/bootcamp/bootcamp.route";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Nav />}>
        <Route path="/" element={<Home />} />
        <Route path="/testimony" element={<Testimony />} />
        <Route path="/our-story" element={<OurStory />} />
        <Route path="/courses/*" element={<Courses />} />
        <Route path="/register" element={<Register />} />
        <Route path="/developers" element={<Developers />} />
        <Route path="/our-trainings" element={<Register />} />
        <Route path="/our-foundation" element={<Foundation />} />
      </Route>
      <Route path="/bootcamp" element={<Bootcamp />}></Route>
    </Routes>
  );
};

export default App;
