import { useNavigate } from "react-router-dom";
// import Pulse from "react-reveal/Pulse";
import { Zoom } from "react-reveal";

import AppButton from "../app-button/app-button.component";

const CourseCard = ({ item }) => {
  let navigate = useNavigate();

  function goToHandler() {
    navigate(`/courses/${item.title}`);
  }
  return (
    <Zoom>
      <div className="max-w-sm bg-white rounded-xl mx-auto shadow">
      <div className="h-40 overflow-hidden">
        <img
          src={require(`../../assets/img/courses/${item.image}`)}
          alt="UI-UX"
          className="rounded-t-xl w-full"
        />
      </div>
        <div className="p-4 flex justify-between items-center">
          <p className="text-md font-medium text-[#000D83]">{item.title}</p>
          <p className="text-[#EFA632] text-md">{item.duration}</p>
        </div>
        <div className="flex justify-center p-2 px-8">
          <AppButton
            children="Enroll"
            buttonType="primary"
            width="full"
            handleButton={goToHandler}
          />
        </div>
      </div>
    </Zoom>
  );
};

export default CourseCard;
